import React, { useCallback } from "react";
import dxForm from "devextreme/ui/form";
import { dateTimeUDF } from "components/Common/Utils/Dates";
import { adminPanelType } from "../../Models/AdminPanel.model";
import { FieldMetaGroup } from "components/Common/Interfaces/Entity.interface";
import { ColumnPropGroup, filterColumnProps } from "components/ContractInFocus/Interfaces/DataGridColumn.interface";
import { JobTitle } from '../Interfaces/People.interface'; //, JobTitlesState
import { FormItem, AdminAction } from "interfaces/form.interface";
import { openPanel } from "components/AdminPanel/Helpers/AdminPanel.helper";
import { getChoicesSortedByDisplayName } from 'components/Common/Utils/Arrays.js';

import { columnVisibility, createdAtColumn } from "helpers/DataGrid/DataGridColumn.helper";
import { RECENT_IN_DAYS } from "components/AdminPanel/Models/AdminPanel.model";
import { store } from 'store/store';

import { dictToArray } from "services/API/API.helper";
import moment from "moment";

export const personAvatar = {
  key: "avatar",
  label: "Avatar",
};

export const peopleDatagridColumns = (
  meta: FieldMetaGroup
): ColumnPropGroup => ({
  first_name: {
    width: 200,
    alignment: "left",
  },
  last_name: {
    width: 250,
    alignment: "left",
  },
  email: {
    minWidth: undefined,
    alignment: "left",
  },
  // organisation: {
  //   dataField: 'organisation_name',
  //   width: 160,
  //   alignment: "left"
  // },
  mobile: {
    width: 200,
    alignment: "left",
  },
});

export const peopleWithAccessColumns = (
  meta: FieldMetaGroup
): ColumnPropGroup => ({
  first_name: {
    width: "10%",
    alignment: "left",
  },
  last_name: {
    width: "15%",
    alignment: "left",
  },
  email: {
    minWidth: undefined,
    width: "20%",
    alignment: "left",
  },
  organisation: {
    //minWidth: undefined,
    width: "15%",
    dataField: "organisation_name",
    alignment: "left",
  },
  // category: {
  //   width: "15%",
  //   dataField: "category",
  //   alignment: "left",
  //   editorOptions: {
  //     showClearButton: true
  //   }
  // },
  ops_data_access: {
    width: "20%",
    gridModeInsert: true,
    gridModeRead: true,
    gridModeEdit: true,
  },
  h_a_s_permit_access: {
    width: "20%",
    gridModeInsert: true,
    gridModeRead: true,
    gridModeEdit: true,
  },
});

export const maintenanceReportDistributionColumns = (
  meta: FieldMetaGroup
): ColumnPropGroup => ({
  first_name: {
    width: "12%",
    alignment: "left",
  },
  last_name: {
    width: "18%",
    alignment: "left",
  },
  email: {
    minWidth: undefined,
    width: "25%",
    alignment: "left",
  },
  organisation: {
    //minWidth: undefined,
    width: "25%",
    dataField: "organisation_name",
    alignment: "left",
  },
});

const firstNameColumn = (width?: number, visible = true) => ({
  first_name: {
    width,
    alignment: "left",
    ...columnVisibility(visible),
  },
});

const lastNameColumn = (width?: number, visible = true) => ({
  last_name: {
    width,
    alignment: "left",
    ...columnVisibility(visible),
  },
});

const emailColumn = (width?: number, visible = true) => ({
  email: {
    width,
    alignment: "left",
    ...columnVisibility(visible),
  },
});

const organisationNameColumn = (width?: number, visible = true) => ({
  organisation: {
    width,
    alignment: "left",
    ...columnVisibility(visible),
  },
});

const jobTitleColumn = (width?: number, visible = true) => ({
  job_title: {
    width,
    alignment: "left",
    ...columnVisibility(visible),
  },
});

const isActiveColumn = (width?: number, visible = true) => ({
  is_active: {
    width,
    alignment: "left",
    ...columnVisibility(visible),
  },
});

// const passwordColumn = (width?: number, visible = false) => ({
//   password: {
//     width,
//     alignment: "left",
//     ...columnVisibility(visible),
//   },
// });

const adminLevelColumn = (width?: number, visible = false) => ({
  api_admin_level: {
    width,
    alignment: "left",
    ...columnVisibility(visible),
  },
});

const h_a_s_AdminLevelColumn = (width?: number, visible = false) => ({
  h_a_s_admin_level: {
    width,
    alignment: "left",
    ...columnVisibility(visible),
  },
});

const ptw_AdminLevelColumn = (width?: number, visible = false) => ({
  ptw_admin_level: {
    width,
    alignment: "left",
    ...columnVisibility(visible),
  },
});

const permitTrainingValidToColumn = (width?: number, visible = false) => ({
  permit_training_valid_to: {
    width,
    alignment: "left",
    ...columnVisibility(visible),
  },
});

const confinedSpacesTrainingValidToColumn = (width?: number, visible = false) => ({
  confined_spaces_training_valid_to: {
    width,
    alignment: "left",
    ...columnVisibility(visible),
  },
});

const globalContractLevelColumn = (width?: number, visible = false) => ({
  global_contract_level: {
    width,
    alignment: "left",
    ...columnVisibility(visible),
  },
});

const mobileColumn = (width?: number, visible = false) => ({
  mobile: {
    width,
    alignment: "left",
    ...columnVisibility(visible),
  },
});

const inviteColumn = (width?: number, visible = false) => ({
  invite: {
    width,
    alignment: "left",
    ...columnVisibility(visible),
  },
});

export function getPeopleFilterExpForGrid(props: any, headerFilterPage: string) {
  //@ts-ignore
  return headerFilterPage == "default" || headerFilterPage == "all" ? null :
    //@ts-ignore
    headerFilterPage == "recent" ? ["created_at", ">", moment().add(-RECENT_IN_DAYS, 'day')] :
      //@ts-ignore
      headerFilterPage == "jaguarEmployees" ? ["internal_member", "=", true] :
        //@ts-ignore
        headerFilterPage == "client" ? ["client_member", "=", true] : []
}

export const peopleFilteredDatagridColumns = (
  props: filterColumnProps
) => {
  return {
    recent: {
      caption: "Recent",
      onClick: () => props.setHeaderFilterPage("recent"),
      columns: (meta: FieldMetaGroup): any => ({
        ...firstNameColumn(200),
        ...lastNameColumn(250),
        ...emailColumn(),
        ...permitTrainingValidToColumn(),
        ...confinedSpacesTrainingValidToColumn(),
        ...organisationNameColumn(),
        ...createdAtColumn(),
        ...jobTitleColumn(undefined, false),
        //...passwordColumn(undefined, false),
        ...adminLevelColumn(undefined, false),
        ...h_a_s_AdminLevelColumn(undefined, false),
        ...ptw_AdminLevelColumn(undefined, false),
        ...globalContractLevelColumn(undefined, false),
        ...mobileColumn(undefined, false),
        ...inviteColumn(undefined, false),
        ...isActiveColumn(undefined, props.includeIsActive),
      }),
    },
    client: {
      caption: "Clients",
      onClick: () => props.setHeaderFilterPage("client"),
      columns: (meta: FieldMetaGroup): any => ({
        ...firstNameColumn(200),
        ...lastNameColumn(250),
        ...emailColumn(),
        ...permitTrainingValidToColumn(),
        ...confinedSpacesTrainingValidToColumn(),
        ...organisationNameColumn(),
        ...createdAtColumn(),
        ...jobTitleColumn(undefined, false),
        //...passwordColumn(undefined, false),
        ...adminLevelColumn(undefined, false),
        ...h_a_s_AdminLevelColumn(undefined, false),
        ...ptw_AdminLevelColumn(undefined, false),
        ...globalContractLevelColumn(undefined, false),
        ...mobileColumn(undefined, false),
        ...inviteColumn(undefined, false),
        ...isActiveColumn(undefined, props.includeIsActive),
      }),
    },
    jaguarEmployees: {
      caption: "Jaguar Employees",
      onClick: () => props.setHeaderFilterPage("jaguarEmployees"),
      columns: (meta: FieldMetaGroup): any => ({
        ...firstNameColumn(200),
        ...lastNameColumn(250),
        ...emailColumn(),
        ...permitTrainingValidToColumn(200, true),
        ...confinedSpacesTrainingValidToColumn(200, true),
        ...organisationNameColumn(undefined, false),
        ...createdAtColumn(),
        ...jobTitleColumn(undefined, false),
        //...passwordColumn(undefined, false),
        ...adminLevelColumn(undefined, false),
        ...h_a_s_AdminLevelColumn(undefined, false),
        ...ptw_AdminLevelColumn(undefined, false),
        ...globalContractLevelColumn(undefined, false),
        ...mobileColumn(undefined, false),
        ...inviteColumn(undefined, false),
        ...isActiveColumn(undefined, props.includeIsActive),
      }),
    },
    default: {
      caption: "All",
      onClick: () => props.setHeaderFilterPage("default"),
      columns: (meta: FieldMetaGroup): any => ({
        ...firstNameColumn(200),
        ...lastNameColumn(250),
        ...emailColumn(),
        ...permitTrainingValidToColumn(),
        ...confinedSpacesTrainingValidToColumn(),
        ...organisationNameColumn(),
        ...createdAtColumn(),
        ...jobTitleColumn(undefined, false),
        //...passwordColumn(undefined, false),
        ...adminLevelColumn(undefined, false),
        ...h_a_s_AdminLevelColumn(undefined, false),
        ...ptw_AdminLevelColumn(undefined, false),
        ...globalContractLevelColumn(undefined, false),
        ...mobileColumn(undefined, false),
        ...inviteColumn(undefined, false),
        ...isActiveColumn(undefined, props.includeIsActive),
      }),
    },
  };
};

export const getPersonFormActions: AdminAction[] = [
  // {
  //   key: 'open-org',
  //   caption: 'Open Organisation',
  //   dataField: 'organisation',
  //   onClick: () => store.dispatch(refresh())
  //   // onClick: (id: any) => openPanel(adminPanelType.organisations, id)
  // },
  // {
  //   key: 'open-org2',
  //   caption: 'Open Organisation',
  //   dataField: 'organisation',
  //   onClick: () => store.dispatch((() => ({type: "refresh 2"}))())
  //   // onClick: (id: any) => openPanel(adminPanelType.organisations, id)
  // }
];

export const getPeopleWithAccessActions = (
  unlinkCB?: (item: any) => void
): AdminAction[] => {
  const openAction = {
    key: "open-person",
    icon: "user",
    dataField: "id",
    onClick: (data: any) => {
      return openPanel({
        panelType: adminPanelType.people,
        expandRecordId: data['person']
      })

    }
  };

  return unlinkCB
    ? [
      openAction,
      {
        key: "unlink-person",
        icon: "fas fa-unlink",
        dataField: "id",
        onClick: unlinkCB,
      },
    ]
    : [openAction];
};

export const getPersonGridActions = (
  unlinkCB?: (item: any) => void
): AdminAction[] => {
  const openAction = {
    key: "open-person",
    icon: "user",
    dataField: "id",
    //onClick: (id: any) => {
    onClick: (data: any) => {
      return openPanel({
        panelType: adminPanelType.people,
        expandRecordId: data['id']
      })
    }
  };
  return unlinkCB
    ? [
      openAction,
      {
        key: "unlink-person",
        icon: "fas fa-unlink",
        dataField: "id",
        onClick: unlinkCB,
      },
    ]
    : [openAction];
};

const callBackLogger = (e: any) => {
  console.log('here are e items: ', e.component.option("items"))
}
export const getPersonFormItems = (
  meta: FieldMetaGroup,
  formForMembers: boolean = false,
  jobTitles: JobTitle[],
): FormItem[] => {
  const personFormItems = [
    {
      dataField: "id",
      visible: false,
    },
    {
      dataField: meta.first_name.field_name,
      editorOptions: {
        readOnly: meta.first_name.read_only,
      },
    },
    {
      dataField: meta.last_name.field_name,
      editorOptions: {
        readOnly: meta.last_name.read_only,
      },
    },
    {
      dataField: meta.email.field_name,
      editorOptions: {
        readOnly: meta.email.read_only,
      },
    },
    // {
    //   dataField: meta.password.field_name,
    //   editorType: "dxTextBox",
    //   editorOptions: {
    //     readOnly: meta.password.read_only,
    //     mode: "password",
    //   },
    // },
    {
      dataField: meta.job_title.field_name,
      editorType: "dxSelectBox",
      editorOptions: {
        searchEnabled: true,
        //dataSource: getChoicesSortedByDisplayName(meta.job_title.choices || []),
        dataSource: jobTitles,
        displayExpr: "display_name",
        valueExpr: "value",
        readOnly: meta.job_title.read_only,
      },
    },
    {
      dataField: meta.mobile.field_name,
      editorOptions: {
        readOnly: meta.mobile.read_only,
      },
    },
    {
      dataField: meta.invite.field_name,
      editorOptions: {
        readOnly: meta.invite.read_only,
        defaultValue: false
      },
      editorType: "dxCheckBox",
    },
    {
      dataField: meta.is_active.field_name,
      editorOptions: {
        readOnly: meta.is_active.read_only,
        defaultValue: true
      },
      editorType: "dxCheckBox",
    },
    {
      dataField: meta.api_admin_level.field_name,
      editorType: "dxSelectBox",
      editorOptions: {
        searchEnabled: true,
        items: meta.api_admin_level.choices || [],
        displayExpr: "display_name",
        valueExpr: "value",
        readOnly: meta.api_admin_level.read_only,
      },
    },
    {
      dataField: meta.h_a_s_admin_level.field_name,
      label: { text: 'Health And Safety Team Level' },
      editorType: "dxSelectBox",
      editorOptions: {
        searchEnabled: true,
        items: meta.h_a_s_admin_level.choices || [],
        displayExpr: "display_name",
        valueExpr: "value",
        readOnly: meta.h_a_s_admin_level.read_only,
      },
    },
    {
      dataField: meta.permit_training_valid_to.field_name,
      label: { text: 'Permit Training Valid To' },
      editorType: "dxDateBox",
      editorOptions: {
        displayFormat: 'dd/MM/yyyy',
        readOnly: meta.permit_training_valid_to.read_only,
        type: 'date',
        format: 'yyy-MM-dd',
      },
    },
    {
      dataField: meta.confined_spaces_training_valid_to.field_name,
      label: { text: 'Confined Spaces Training Valid To' },
      editorType: "dxDateBox",
      editorOptions: {
        displayFormat: 'dd/MM/yyyy',
        readOnly: meta.confined_spaces_training_valid_to.read_only,
        type: 'date',
        format: 'yyy-MM-dd',
      },
    },
    // {
    //   dataField: meta.ptw_admin_level.field_name,
    //   editorType: "dxSelectBox",
    //   editorOptions: {
    //     searchEnabled: true,
    //     items: meta.ptw_admin_level.choices || [],
    //     displayExpr: "display_name",
    //     valueExpr: "value",
    //     readOnly: meta.ptw_admin_level.read_only,
    //   },
    // },
    {
      dataField: meta.global_contract_level.field_name,
      editorType: "dxSelectBox",
      editorOptions: {
        searchEnabled: true,
        items: meta.global_contract_level.choices || [],
        displayExpr: "display_name",
        valueExpr: "value",
        readOnly: meta.global_contract_level.read_only,
      },
    },
  ];

  const organisationPersonFormItem = {
    dataField: meta.organisation.field_name,
    editorType: "dxSelectBox",
    editorOptions: {
      searchEnabled: true,
      //items: getChoicesSortedByDisplayName(dictToArray(store.getState().organisations.lookup)),
      dataSource: getChoicesSortedByDisplayName(dictToArray(store.getState().organisations.lookup)),
      displayExpr: "display_name",
      valueExpr: "value",
      readOnly: meta.organisation.read_only,
      //onContentReady: callBackLogger
    },
  };

  return formForMembers ? personFormItems : [organisationPersonFormItem, ...personFormItems];
};

export const newPerson = {
  url: "",
  first_name: "",
  last_name: "",
  email: "",
  mobile: "",
  avatar: {
    thumbnail: "",
  },
  organisation: "",
};

export const createLinkedPerson = (link: any, linkId: any) =>
({
  [adminPanelType.organisations]: { ...newPerson, organisation: linkId },
}[link]);
